<template>
  <div id="graph">
    <transition-group name="fade">

      <div key="3">
        <div
          class="grid"
        >
          <div>
            <table class="table first-table">
              <thead class="table-headers">
              <tr>
                <th class="first-header">Principal Diagnosis</th>
                <th class="second-header second-header-ye">Your Entity</th>
              </tr>
              </thead>
              <tbody v-if="diagnosisUsageByHospital.length > 0">
              <tr class="table-rows" v-for="(diagnosis, index) in diagnosisUsageByHospital" :key="index"
                  @click="selectRow(diagnosis)"
                  :class="{selected: isSelected(diagnosis)}">
                <td class="diagnosis-object-name">{{ diagnosis.diagnosisShortDescription }}</td>
                <td class="chart-cont-and-percent">
                  <div class="flex-container">
                    <div class="chart-container"
                         v-bind:style="{ width: ((diagnosis.percentage * 100).toFixed(2) / maxPercentageByHospital) + '%' }">
                    </div>
                    <div class="chart-percent">{{ (diagnosis.percentage * 100).toFixed(2) }}%
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
              <h2 v-else class="no-results"> There is no available data within the specified parameters.</h2>
            </table>

            <!--         By design, the table below should not have selectable rows.-->
            <table class="table second-table">
              <thead class="table-headers">
              <tr>
                <th class="first-header">Principal Diagnosis</th>
                <th class="second-header second-header-rwd">Real World Data</th>
              </tr>
              </thead>
              <tbody v-if="diagnosisUsageByDataSegment.length > 0">
              <tr class="table-rows" v-for="(diagnosis, index) in diagnosisUsageByDataSegment" :key="index"
              >
                <td class="diagnosis-object-name">{{ diagnosis.diagnosisShortDescription }}</td>
                <td class="chart-cont-and-percent">
                  <div class="flex-container">
                    <div class="chart-container chart-container-rwd"
                         v-bind:style="{ width: ((diagnosis.percentage * 100).toFixed(2) / maxPercentageByDataSegment) + '%' }">
                    </div>
                    <div class="chart-percent">{{ (diagnosis.percentage * 100).toFixed(2) }}%
                    </div>
                  </div>
                </td>
              </tr>
              </tbody>
              <h2 v-else class="no-results"> There is no available data within the specified parameters.</h2>
            </table>
          </div>
        </div>
        <br/>
        <p class="center-align">
          <button class="btn btn-text" @click="loadMoreDiagnosisAndRWD()"
                  :disabled="isLoadMoreDisabled">Load More
          </button>
        </p>
        <div>
          <div class="flex flex-row-reverse rfs-m-8-t">
            <button
              class="btn btn-primary"
              @click="saveDiagnosisGroup()"
              :disabled="isSaveDisabled"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {inject} from "@vue/composition-api";
import {eventsHub} from "@/main";

var axios = require("axios");
export default {
  name: 'DiagnosisInsightsTable',
  setup() {
    // eslint-disable-next-line no-unused-vars
    const workflowStore = inject('populationWorkflowStore');
  },
  props: {
    countData: Array,
    ndcCodes: Array,
    drugGroupIds: Array,
    dataSegments: Array
  },
  inject: ['populationWorkflowStore'],
  computed: {
    colWidth() {
      return 'width: ' + 100 / this.insights.length + '%;';
    },
    selectedHospitalList() {
      return this.$store.getters['auth/getSelectedHospitalList'];
    },
    selectedDrugGroupList() {
      return this.localDrugGroupIds;
    },
    maxPercentageByHospital() {
      const percentages = this.diagnosisUsageByHospital.map((diagnosis) => diagnosis.percentage);
      return Math.max(...percentages);
    },
    maxPercentageByDataSegment() {
      const percentages = this.diagnosisUsageByDataSegment.map((diagnosis) => diagnosis.percentage);
      return Math.max(...percentages);
    },
    isSaveDisabled() {
      return this.selectedRows.length === 0;
    },
    isLoadMoreDisabled() {
      return (this.diagnosisUsageByHospital.length === 0 && this.diagnosisUsageByDataSegment.length === 0);
    },
    dateRange: {
      get() {
        return this.populationWorkflowStore.getDateRange();
      },
      set(value) {
        this.populationWorkflowStore.setDateRange(value);
      }
    },
  },

  watch: {
    selectedHospitalList() {
      this.refreshList();
    },
    dateRange(newVal, oldVal) {
      this.refreshList();
    },
    ndcCodes(newVal, oldVal) {
      this.offset = 0;
      this.refreshList();
    },
    selectedDrugGroupList(newVal, oldVal) {
      this.refreshList();
    },
  },
  data() {
    return {
      diagnoses: [],
      diagnosisUsageByHospital: [],
      diagnosisUsageByDataSegment: [],
      selectedRows: [],
      selectedRowsFromInsights: [],
      selectedRowClass: 'selected-row',
      insightsHeaderClasses: 'rfs-24 rfs-m-2-t rfs-m-2-b',
      localDrugGroupIds: [],
      pageSize: 15,
      offset: 0,
      cancelTokenSource: null
    };
  },
  created() {
    eventsHub.$on('medicationList', v => {
      this.localDrugGroupIds = v;
      this.refreshList();
    });
    eventsHub.$on('deleteMedicationGroup', () => {
      this.localDrugGroupIds = [];
      this.refreshList();
    })
    this.refreshList();
  },
  methods: {
    selectRow(diagnosis) {
      let index = this.selectedRows.findIndex(r => r === diagnosis);
      if (index > -1) {
        this.selectedRows.splice(index, 1);
      } else {
        this.selectedRows.push(diagnosis);
      }
      // This is to ensure we pass the updated array to DiagnosisGroupCreate when we want to save.
      this.$emit('selected-rows-updated', this.selectedRows);
    },
    isSelected(diagnosis) {
      return this.selectedRows.some(r => r === diagnosis) ? this.selectedRowClass : '';
    },
    saveDiagnosisGroup() {
      this.selectedRowsFromInsights = this.selectedRows;
      this.$router.push({
        name: 'DiagnosisGroupCreate',
        params: {
          selectedRows: JSON.stringify(this.selectedRows.map
          (row => ({description: row.diagnosisShortDescription, code: row.diagnosisCode})))
        }
      });
    },
    refreshList() {
      // If any of the parameters changed, we reset the offset and ensure we replace the diagnoses arrays.
      let selectedHospitalIds = this.selectedHospitalList.map(hospital => hospital.value);
      let selectedDrugGroupIds = this.selectedDrugGroupList.map(drug => drug.code);
      this.offset = 0;
      this.getData(selectedHospitalIds, selectedDrugGroupIds, false);
    },

    loadMoreDiagnosisAndRWD() {
      // If the Load More button is pressed, we inform the getData method that no parameters
      // have changed, so we will append the contents of the diagnoses arrays instead of replacing them.
      this.offset += 15;
      let selectedHospitalIds = this.selectedHospitalList.map(hospital => hospital.value);
      let selectedDrugGroupIds = this.selectedDrugGroupList.map(drug => drug.code);
      this.getData(selectedHospitalIds, selectedDrugGroupIds, true);
    },

    getData(selectedHospitalIds, selectedDrugGroupIds, hasIdenticalParameters) {
      if (this.cancelTokenSource) {
        // Cancel the old API call if a new one is in progress.
        this.cancelTokenSource.cancel('New request initiated');
      }
      // Flagging the API call as being in progress.
      this.cancelTokenSource = axios.CancelToken.source();

      axios.get('/data-service/diagnosisAnalytics/topDiagnoses', {
        cancelToken: this.cancelTokenSource.token,
        params: {
          beginDate: this.dateRange.start,
          endDate: this.dateRange.end,
          hospitalIds: selectedHospitalIds,
          ndcs: this.ndcCodes,
          drugGroupIds: selectedDrugGroupIds,
          offset: this.offset,
          pageSize: this.pageSize,
          dataSegments: this.dataSegments
        },
        // The paramsSerializer below will remove the brackets from the parameters.
        paramsSerializer: params => {
          return Object.entries(params)
            .map(([key, value]) => {
              if (Array.isArray(value)) {
                return `${key}=${value.join(',')}`;
              } else {
                return `${key}=${value}`;
              }
            }).join('&');
        }
      }).then((response) => {
        if (hasIdenticalParameters) {
          this.diagnosisUsageByHospital.push(...response.data.diagnosisUsageByHospital.topDiagnoses);
          this.diagnosisUsageByDataSegment.push(...response.data.diagnosisUsageByDataSegment.topDiagnoses);
        } else {
          this.diagnosisUsageByHospital = response.data.diagnosisUsageByHospital.topDiagnoses;
          this.diagnosisUsageByDataSegment = response.data.diagnosisUsageByDataSegment.topDiagnoses;
        }
      }).catch((error) => {
        if (axios.isCancel(error) || this.cancelTokenSource.token) {
          console.warn('An API call is already in progress. Restoring the new table values...');
        } else {
          console.error(error);
        }
      });
    },
  },
  toggleSelection(diagnosis) {
    diagnosis.selected = !diagnosis.selected;
  },
  openLoader() {
    this.$refs.miamiLoader.openLoader();
  },
  closeLoader() {
    this.$refs.miamiLoader.closeLoader();
  },

};
</script>


<style>
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 16px;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }
}

.table {
  border-collapse: collapse;
  border: 1px solid black;
  width: 100%;
}

.first-table {
  margin-bottom: 4rem;
}

.chart-cont-and-percent {
  display: block;
  text-align: right;
  width: 100%;
}

.first-header {
  background-color: #4d4f57;
  border: 1px solid black;
  text-align: left;
  width: auto;
  padding-left: 0.3rem;
}

.second-header {
  background-color: #abf5d1;
  color: black;
  font-weight: bold;
  border: 1.5px solid black;
}

.second-header-rwd {
  width: 12.3rem;
}

.second-header-ye {
  width: 12.3rem;
}

.diagnosis-insights-primary td {
  background-color: #007bff !important;
  color: #fff;
}

.table-rows:hover {
  background-color: grey;
  cursor: pointer;
}

.selected {
  background-color: grey;
}

.flex-container {
  display: flex;
}

.chart-container {
  height: 18px;
  margin-top: 5px;
  background-color: #1f7890;
}

.chart-container-rwd {
  background-color: #626d72;
}

.chart-percent {
  margin-left: 10px;
  margin-right: 5px;
  width: 90%;
}

.table-rows,
.table-headers {
  padding: 10px;
}

.no-results {
  padding-left: 0.3rem;
}

.diagnosis-object-name {
  padding-left: 0.3rem;
}
</style>

