<template>
  <div style="position: relative">
    <loader ref="diagnosLoader" fullScreen />
    <div
      class="flex"
      style="position: absolute; top: -64px; right: 0"
      v-if="$route.name === 'DiagnosisGroupEdit'"
    >
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'DiagnosisGroupCreate' }"
        v-if="!buttonsDisabled"
        >New</router-link
      >
      <button
        class="btn btn-contrast rfs-m-4-l"
        @click="saveAsNew"
        :disabled="buttonsDisabled"
      >
        Save As New
      </button>
    </div>
    <div class="rfs-m-8-b">
      <text-input :key="keyName" v-bind="textParams" v-model="diagnoGrpName" />
      <p class="error" v-if="isDGNMissing">
        Please provide Diagnosis group name.
      </p>
    </div>

    <CodeLookup
      :key="keyLookup"
      v-bind="lookupProps"
      @input="updateDignosisList"
    ></CodeLookup>

    <div
      class="
        grid grid-cols-2
        gap-8
        selection-table
        crca-datatable
        rfs-m-4-t
        selection-table
      "
    >
      <div class="rfs-m-8-t">
        <button
          v-if="$route.name === 'DiagnosisGroupEdit'"
          class="btn btn-danger"
          @click="deleteGroup"
          :disabled="buttonsDisabled"
        >
          Delete Group
        </button>
      </div>
      <div>
        <div class="flex flex-row-reverse rfs-m-8-t">
          <button
            class="btn btn-primary"
            @click="saveDiagnosisGroup"
            :disabled="isSaveDisabled"
          >
            Save
          </button>
          <button
            v-if="$route.name !== 'DiagnosisGroupEdit'"
            class="btn btn-text rfs-m-4-r"
            @click="clearGroup"
            :disabled="isClearDisabled"
          >
            Clear
          </button>
        </div>
      </div>
    </div>

    <v-dialog style="z-index: 10000" />
  </div>
</template>

<script>
  import TextInput from '../../../components/base/forms/text_input';
  import CodeLookup from '../../../components/filters/CodeLookup';
  import LengthOfStayInsightsTable from "../../../components/popbuilder/LengthOfStayInsightsTable.vue";
  import DiagnosisInsightsTable from '../../../components/popbuilder/DiagnosisInsightsTable.vue';

  export default {
    name: 'DiagnosisGroupCreate',
    components: {
      TextInput,
      CodeLookup,
      LengthOfStayInsightsTable,
      DiagnosisInsightsTable
    },
    data() {
      return {
        keyLookup: 1,
        keyName: 2,
        selectedRows: this.getRowsFromDiagInsights(),
        groupId: this.$route.params.groupId,
        textParams: {
          id: 'drugGroupName',
          fieldLabel: 'Group Name'
        },
        lookupProps: {
          searchId: 'searchDrug',
          searchLabel: 'Diagnosis Lookup',
          codeTypes: [
            {
              id: 'ccss',
              apiPath: 'ccss',
              name: 'CCS'
            },
            {
              id: 'icd10s',
              apiPath: 'icd10s',
              name: 'ICD-10'
            }
          ],
          uniqueIdentifier: 'code',
          columns: [
            {
              text: 'Code',
              datafield: 'code'
            },
            {
              text: 'Description',
              datafield: 'description'
            }
          ],
          initialSort: {
            column: 'description',
            direction: 'asc'
          },
          value: {
            inputVal: [],
            apiPath: 'icd10s'
          }
        },
        diagnoGrpName: undefined,
        drugs: {
            inputVal: [],
            apiPath: 'icd10s'
        },
        watchers: {
          groupId: null
        },
        buttonsDisabled: true,
        isDGNMissing: false,
        isClearDisabled: true,
        isSaveDisabled: true,
        accessedFromInsightsTable: false
      };
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        if (from.name === 'PopulationBuilder' &&
          to.params.selectedRows &&
          to.params.selectedRows.length > 0) {
          vm.accessedFromInsightsTable = true;
        }
      });
    },
    watch: {
      diagnoGrpName() {
        this.validateFields();
      }
    },
    methods: {
      updateKeyLookup() {
        this.keyLookup = Math.random();
      },
      getRowsFromDiagInsights() {
        let selectedRows = [];
        try {
          selectedRows = JSON.parse(this.$route.params.selectedRows);
        }
        catch (e){
          return selectedRows;
        }
        return selectedRows;
      },
      updateKeyName() {
        this.keyName = Math.random();
      },
      init() {
        this.clearGroup();
        this.groupId = this.$route.params.groupId;

        if (this.groupId) {
          this.buttonsDisabled = false;
          this.isSaveDisabled = false;
          this.$store.dispatch('diagnosisGroupsManagement/getDiagnosisGroup', this.groupId)
            .then((resp) => {
              const diagnosisGrp = resp.data;
              // update UI with data
              this.diagnoGrpName = diagnosisGrp.name;
              this.drugs.inputVal = diagnosisGrp.valueCodes;
              this.drugs.apiPath = diagnosisGrp.codeType === 'CCS' ? 'ccss' : 'icd10s';
              this.lookupProps.value.inputVal = diagnosisGrp.valueCodes;
              this.lookupProps.value.apiPath = this.drugs.apiPath;
              this.updateKeyLookup();
              this.updateKeyName();
            });
        }
        else if(this.$route.params.selectedRows!==null)
        {
          this.lookupProps.value.inputVal = this.$route.params.selectedRows;
        } else {
          this.clearGroup();
          this.lookupProps.value.inputVal = [];
          this.lookupProps.value.apiPath = 'icd10s';
        }
      },
      validateFields() {
        if (this.drugs.inputVal!==null && this.drugs.inputVal !== undefined
          && this.drugs.inputVal.length > 0) {
          this.isClearDisabled = false;
          if(this.diagnoGrpName !== undefined && this.diagnoGrpName !== null
            && this.diagnoGrpName.trim().length > 0) {
            this.isSaveDisabled = false;
          } else {
            this.isSaveDisabled = true;
          }

        } else {
          if (this.accessedFromInsightsTable) {
            this.isSaveDisabled = false;
            this.isClearDisabled = false;
          } else {
            this.isSaveDisabled = true;
            this.isClearDisabled = true;
          }
        }
      },
      updateDignosisList(dgList) {
        if (dgList !== undefined && dgList.inputVal.length > 0) {
            this.drugs = dgList;
            this.buttonsDisabled = false;
        } else {
          this.buttonsDisabled = true;
          this.drugs = {
            inputVal: [],
            apiPath: dgList.apiPath
          }
        }
        this.validateFields();
      },
      saveAsNew() {
        this.saveDiagnosisGroup(null, true);
      },
      saveDiagnosisGroup(e, sNew = false) {
        if (
          this.diagnoGrpName === undefined ||
          this.diagnoGrpName.trim() === ''
        ) {
          this.isDGNMissing = true;
          return;
        }
        this.$refs.diagnosLoader.openLoader();
        const dataObj = {
          name: this.diagnoGrpName,
          diagnosisCodeType: this.drugs.apiPath === 'ccss' ? 'CCS' : 'ICD10',
          diagnosisCodes: this.drugs.inputVal.map((dig) => dig.code)
        };
        if (this.groupId !== undefined && !sNew) {
          dataObj.id = this.groupId;
        }
        // Fix for data coming from DiagnosisInsightsTable
        if (dataObj.diagnosisCodes.length === 0) {
          dataObj.diagnosisCodes = this.selectedRows.map(dig => dig.code);
        }
        this.$store
          .dispatch(
            'diagnosisGroupsManagement/createOrUpdateDiagnosisGroup',
            dataObj
          )
          .then((resp) => {
            this.$refs.diagnosLoader.closeLoader();
            if (resp.success) {
              this.$snotify.success(
                this.groupId !== undefined && this.groupId !== null
                  ? 'Diagnosis group updated!'
                  : 'Diagnosis group saved!'
              );
              this.$router.push({
                name: 'DiagnosisGroupsManagement'
              });
            }
          })
          .catch((resp) => {
            this.$refs.diagnosLoader.closeLoader();
          });
      },
      clearGroup() {
        this.groupId = null;
        this.diagnoGrpName = undefined;
        this.drugs.inputVal = [];
        this.keyLookup = Math.random();
        this.keyName = Math.random();
        this.buttonsDisabled = true;
      },
      deleteGroup() {
        this.buttonsDisabled = true;
        this.$snotify.confirm(
          'Removing this Diagnosis Group cannot be undone.',
          'Are you sure?',
          {
            buttons: [
              {
                text: 'Delete it!',
                action: (toast) => {
                  this.$store
                    .dispatch(
                      'diagnosisGroupsManagement/deleteDiagnosisGroup',
                      this.groupId
                    )
                    .then((resp) => {
                      if (resp.data.isSuccess) {
                        this.$snotify.success('Diagnosis group deleted!');

                        this.$router.push({
                          name: 'DiagnosisGroupsManagement'
                        });
                      } else {
                        this.$snotify.error('Diagnosis group delete failed!');
                      }
                    })
                    .finally(() => {
                      this.buttonsDisabled = false;
                    });

                  this.$snotify.remove(toast.id);
                },
                bold: false
              },
              {
                text: 'Get Me Outa Here!',
                action: (toast) => {
                  this.$snotify.remove(toast.id);
                  this.buttonsDisabled = false;
                }
              }
            ],
            backdrop: 0.5
          }
        );
      }
    },
    created() {
      this.init();
    },
    mounted() {
      this.watchers.groupId = this.$store.watch(
        () => this.$route.params.groupId,
        () => {
          this.init();
        }
      );
    },
    beforeDestroy() {
      // unwatch so bad things don't leak out
      this.watchers.groupId();
    }
  };
</script>

<style>
  .error {
    color: #ef9a9a;
    font-size: 12px;
    padding-bottom: 0;
  }
</style>
