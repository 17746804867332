<template>
  <div style="position: relative">
    <div
      class="flex"
      style="position: absolute; top: -64px; right: 0"
      v-if="$route.name === 'MedicationGroupEdit'"
    >
      <!-- <text-input :key="keyName" v-bind="textParams" v-model="saveData.name" class="flex-grow" /> -->
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'MedicationGroupCreate' }"
        v-if="!buttonsDisabled"
        >New</router-link
      >
      <button
        class="btn btn-contrast rfs-m-4-l"
        @click="saveAsGroup"
        :disabled="buttonsDisabled"
      >
        Save As New
      </button>
    </div>

    <div class="rfs-m-8-b">
      <text-input :key="keyName" v-bind="textParams" v-model="saveData.name" @input=validateButtonDisable />
    </div>

    <loader ref="medicationLoader" fullScreen />
    <CodeLookup
      :key="keyLookup"
      v-bind="lookupProps"
       v-model.trim="saveData.ndcs"
      @input="validateButtonDisable"
    ></CodeLookup>

    <div
      class="
        grid grid-cols-2
        gap-8
        selection-table
        crca-datatable
        rfs-m-4-t
        selection-table
      "
    >
      <div class="rfs-m-8-t">
        <button
          v-if="$route.name === 'MedicationGroupEdit'"
          class="btn btn-danger"
          @click="deleteGroup"
          :disabled="buttonsDisabled"
        >
          Delete Group
        </button>
      </div>

      <div>
        <div class="flex flex-row-reverse rfs-m-8-t">
          <button
            class="btn btn-primary"
            @click="saveGroup"
            :disabled="saveButtonDisabled"
          >
            Save
          </button>
          <button
            v-if="$route.name !== 'MedicationGroupEdit'"
            class="btn btn-text rfs-m-4-r"
            @click="clearGroup"
            :disabled="clearButtonDisabled"
          >
            Clear
          </button>
          <div style="padding: 5px">
            <span v-if="ndcGroupLimit" style="color: yellow; font-size: 16px">
              NDC should be less than 5000
            </span>
          </div>
        </div>
      </div>
    </div>

    <v-dialog style="z-index: 10000" />
  </div>
</template>

<script>
  import TextInput from '../../../components/base/forms/text_input';
  import CodeLookup from '../../../components/filters/CodeLookup';

export default {
  name: 'MedicationGroupCreate',
  components: {
    TextInput,
    CodeLookup
  },
  data() {
    return {
      keyLookup: 1,
      keyName: 2,
      groupId: this.$route.params.groupId,
      textParams: {
        id: 'drugGroupName',
        fieldLabel: 'Group Name'
      },
      lookupProps: {
        searchId: 'searchCodeNdc',
        searchLabel: 'Drug Lookup',
        codeTypes: [
          {
            id: 'ndcsv2',
            apiPath: 'ndcsv2',
            name: 'NDC'
          }
        ],
        uniqueIdentifier: 'ndc',
        columns: [
          {
            text: 'NDC',
            datafield: 'ndc',
            pinned: true,
            width: 125
          },
          {
            text: 'NDC Description',
            datafield: 'ndcDescription',
            pinned: true,
            width: 200
          },
          {
            text: 'GPI',
            datafield: 'drugGpi',
            width: 150
          },
          {
            text: 'GPI Name',
            datafield: 'drugGpiName',
            width: 200
          },
          {
            text: 'USC',
            datafield: 'drugUsc',
            width: 80
          },
          {
            text: 'USC Description',
            datafield: 'drugUscDescription',
            width: 200
          },
          {
            text: 'AHFS Class',
            datafield: 'drugAhfsClass',
            width: 100
          },
          {
            text: 'AHFS Class Description',
            datafield: 'drugAhfsClassDescription',
            width: 200
          },
          {
            text: 'Manufacturer Name',
            datafield: 'drugMfrName',
            width: 200
          }
        ],
        initialSort: {
          column: 'ndcDescription',
          direction: 'asc'
        }
      },
      saveData: {
        name: null,
        ndcs: {
          inputVal: [],
          apiPath: 'ndcsv2'
        }
      },
      watchers: {
        groupId: null
      },
      buttonsDisabled: false,
      saveButtonDisabled:true,
      clearButtonDisabled:true

    };
  },
  computed: {
    ndcGroupLimit() {
      if (this.saveData.ndcs.inputVal.length > 5000) {
        this.saveButtonDisabled = true;
        return true;
      }
    }
  },

  methods: {
    updateKeyLookup() {
      this.keyLookup = Math.random();
    },
    updateKeyName() {
      this.keyName = Math.random();
    },
    init() {
      this.clearGroup();
      this.validateButtonDisable();
      this.groupId = this.$route.params.groupId;

        if (this.groupId) {
          this.saveButtonDisabled=false;
          this.$refs.medicationLoader.openLoader();
          // Get the group information
          this.$store
            .dispatch(
              'medicationGroupsManagement/getSingleSavedList',
              this.groupId
            )
            .then((resSingleNDC) => {
              let list = resSingleNDC;
              // Get the NDC details
              this.$store
                .dispatch(
                  'medicationGroupsManagement/getSingleSavedListNDCDetails',
                  this.groupId
                )
                .then((resNdcDetail) => {
                  this.$refs.medicationLoader.closeLoader();
                  let ndcDetails = resNdcDetail;

                  // Convert the group NDC list, in case anything didn't come back in NDC details
                  let combinedData = list;
                  combinedData.ndcs = list.ndcs.map((orig) => {
                    let ret = {};
                    ret.ndc = orig;
                    return ret;
                  });

                  // Now we have to combine the lists
                  for (let i in combinedData.ndcs) {
                    for (let j in ndcDetails) {
                      if (combinedData.ndcs[i].ndc == ndcDetails[j].ndc) {
                        combinedData.ndcs[i] = ndcDetails[j];
                      }
                    }
                  }

                combinedData.ndcs = ndcDetails;
                this.saveData.name = combinedData.name;
                this.saveData.ndcs.inputVal = combinedData.ndcs;

                  // Make the components update
                  this.updateKeyLookup();
                  this.updateKeyName();
                });
            });
        }
      },
      saveGroup() {
        this.saveGroupHelper();
      },
      saveAsGroup() {
        this.saveGroupHelper(true);
      },
      saveGroupHelper(saveas) {
        this.$refs.medicationLoader.openLoader();
        this.$store
          .dispatch('medicationGroupsManagement/save', {
            id: saveas ? null : this.groupId,
            data: {
              name: this.saveData.name,
              ndcs: this.saveData.ndcs.inputVal.map((item) => item.ndc)
            }
          })
          .then((ret) => {
            this.$refs.medicationLoader.closeLoader();
            this.$store.commit(
              'medicationCardGroupsManagement/setDrugGroupsDirty',
              true
            );
            if (ret.success) {
              // let user know if saving was successful
              this.$snotify.success('Medication group Saved');
            }
            this.$router.push({
              name: 'MedicationGroupManageCards',
              params: { forceReload: true }
            });
          })
        .catch(() => {
          this.$refs.medicationLoader.closeLoader();
        });
      },
    validateButtonDisable(){
      if(this.saveData.ndcs.inputVal!==null && this.saveData.ndcs.inputVal !== undefined &&
        this.saveData.ndcs.inputVal.length > 0) {
        this.clearButtonDisabled=false;
        if(this.saveData.name !== undefined && this.saveData.name !== null &&
          this.saveData.name.trim().length > 0 && this.saveData.ndcs.inputVal.length <= 5000 ) {
          this.saveButtonDisabled = false;
        } else {
          this.saveButtonDisabled = true;
        }

      } else {
        this.saveButtonDisabled = true;
        this.clearButtonDisabled = true;
      }
    },
      clearGroup() {
        this.groupId = null;
        this.saveData.name = null;
        this.saveData.ndcs.inputVal = [];
        this.clearButtonDisabled=true;
        this.saveButtonDisabled=true;
        this.updateKeyLookup();
        this.updateKeyName();
      },
      deleteGroup() {
        this.buttonsDisabled = true;

        this.$snotify.confirm(
          'Removing this Medication Group cannot be undone.',
          'Are you sure?',
          {
            buttons: [
              {
                text: 'Delete it!',
                action: (toast) => {
                  this.$store
                    .dispatch('medicationGroupsManagement/delete', this.groupId)
                    .then(() => {
                      this.$snotify.success('Medication Group deleted!');

                      this.$store.commit(
                        'medicationCardGroupsManagement/setDrugGroupsDirty',
                        true
                      );

                      this.$router.push({
                        name: 'MedicationGroupManageCards',
                        params: { forceReload: true }
                      });
                    })
                    .finally(() => {
                      this.buttonsDisabled = false;
                    });

                this.$snotify.remove(toast.id);
              },
              bold: false
            },
            {
              text: 'Get Me Outa Here!',
              action: (toast) => {
                this.$snotify.remove(toast.id);
                this.buttonsDisabled = false;
              }
            }
          ],
            backdrop: 0.5
        }
      );
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.init();
    this.watchers.groupId = this.$store.watch(
      () => this.$route.params.groupId,
      () => {
        this.init();
      }
    );
  },
  beforeDestroy() {
    // unwatch so bad things don't leak out
    this.watchers.groupId();
  }
};
</script>

